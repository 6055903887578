$main-font-size: 16;

@mixin fs-names {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
}

@mixin fs-ttl {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
}

@mixin fs-ttl-mini {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
}

@mixin fs-category {
    font-size: 14px;
    line-height: 1.2;
    text-transform: uppercase;
}

@mixin fs-additional {
    font-size: 14px;
    line-height: 1.5;
    font-weight: normal;
}

@mixin text-nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin img-center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null) {
    -o-object-fit: $fit;
    object-fit: $fit;
    @if $position {
        -o-object-position: $position;
        object-position: $position;
        font-family: "object-fit: #{$fit}; object-position: #{$position}";
    } @else {
        font-family: "object-fit: #{$fit}";
    }
}

@mixin fontface-simple($font-family, $file-path, $weight: normal, $style: normal, $ext: "all") {
    @font-face {
        font-family: $font-family;
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
        @if $ext == "one" {
            src: url("#{$file-path}.woff2") format("woff2");
        } @else {
            src: url("#{$file-path}.woff2") format("woff2"), url("#{$file-path}.woff") format("woff");
        }
    }
}

@mixin font-face-my($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false) {
    @font-face {
        font-family: $font-family;
        font-weight: $weight;
        font-style: $style;
        font-display: swap;

        @if $asset-pipeline == true {
            src: font-url("#{$file-path}.eot");
            src: font-url("#{$file-path}.eot?#iefix") format("embedded-opentype"), font-url("#{$file-path}.woff") format("woff"),
                font-url("#{$file-path}.ttf") format("truetype"), font-url("#{$file-path}.svg##{$font-family}") format("svg");
        } @else {
            src: url("#{$file-path}.eot");
            src: url("#{$file-path}.eot?#iefix") format("embedded-opentype"), url("#{$file-path}.woff") format("woff"),
                url("#{$file-path}.ttf") format("truetype"), url("#{$file-path}.svg##{$font-family}") format("svg");
        }
    }
}

@mixin transform-origin($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin; // IE9 only
    transform-origin: $origin;
}

@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
    box-shadow: $shadow;
}

@mixin ep-large-text() {
    font-size: 18px;
    line-height: 32px;
    font-weight: normal;
    color: $color-black;
}

@mixin ep-middle-text() {
    font-size: 16px;
    line-height: 26px;
    font-weight: normal;
    color: $color-black;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null) {
    -o-object-fit: $fit;
    object-fit: $fit;
    @if $position {
        -o-object-position: $position;
        object-position: $position;
        font-family: "object-fit: #{$fit}; object-position: #{$position}";
    } @else {
        font-family: "object-fit: #{$fit}";
    }
}

@function em($pixels, $context: $browser-context) {
    @return #{$pixels/$context}em;
}

@function lh($pixels, $context: $main-font-size) {
    @return #{$pixels/$context};
}

@mixin display-grid {
    display: -ms-grid;
    display: grid;
}

// $columns values should be delimited by a space
@mixin grid-template-columns($columns...) {
    -ms-grid-columns: $columns;
    grid-template-columns: $columns;
}

// $rows values should be delimited by a space
@mixin grid-template-rows($rows...) {
    -ms-grid-rows: $rows;
    grid-template-rows: $rows;
}

// Can be used in combination with above grid-template-X mixins.
// These result in the same output:
// @include grid-template-columns(10px grid-repeat(4, 20px) 30px);
// @include grid-template-columns(10px 20px 20px 20px 20px 30px);
@function grid-repeat($repeat, $stuff: 1fr) {
    $list: ();
    @for $i from 1 through $repeat {
        $list: append($list, $stuff, space);
    }
    @return $list;
}

@mixin grid-column($col-start, $col-end) {
    -ms-grid-column: $col-start;
    -ms-grid-column-span: $col-end - $col-start;
    grid-column: #{$col-start} / #{$col-end};
}

@mixin grid-row($row-start, $row-end) {
    -ms-grid-row: $row-start;
    -ms-grid-row-span: $row-end - $row-start;
    grid-row: #{$row-start} / #{$row-end};
}

@mixin grid-align-self($value) {
    -ms-grid-row-align: $value;
    align-self: $value;
}

@mixin grid-justify-self($value) {
    -ms-grid-column-align: $value;
    justify-self: $value;
}

@mixin flex-center() {
    @include display-flex;
    @include justify-content(center);
    @include align-items(center);
}

@mixin text-line-ellipsis($height: false, $value: 2) {
    display: -webkit-box;
    @if $height != false {
        max-height: #{$height}px;
    }
    -webkit-line-clamp: $value;
    @include box-orient(vertical);
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin phone-extra-small($type: "max") {
    @media (#{$type}-width: 574px) {
        @content;
    }
}

@mixin phone-only {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin tablet-landscape-up {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin tablet-portrait-up {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin desktop-up {
    @media (min-width: 1025px) {
        @content;
    }
}

@mixin desktop-big-up {
    @media (min-width: 1800px) {
        @content;
    }
}

@mixin my-media($px: 0, $type: "max", $dir: "width") {
    @if $type == "max" {
        @media (max-#{$dir}: #{$px}px) {
            @content;
        }
    } @else if $type == "min" {
        @media (min-#{$dir}: #{$px}px) {
            @content;
        }
    }
}

@mixin my-media-interval($pxmin: 0, $pxmax: 0) {
    @media (min-width: #{$pxmin}px) and (max-width: #{$pxmax}px) {
        @content;
    }
}

@mixin ie-only {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

@mixin edge-only {
    @supports (-ms-ime-align: auto) {
        @content;
    }
}

@mixin touch{
    @media (pointer:coarse){
        @content;
    }
}

@mixin lazy-image($mheight: "100%", $contain: false, $height: "auto") {
    &[src$="image.svg"] {
        @if $mheight != "100%" {
            max-height: #{$mheight}px;
        } @else {
            max-height: 100%;
        }
        @if $height != "auto" {
            height: #{$height}px;
        }
        @if $height != false {
            @include object-fit(contain);
        }
    }
}

@function get-vw($min, $target) {
    $vw-context: (1920 * 0.01) * 1;
    $vw-converted: ($min/$vw-context);
    @return calc(#{$min + px} + #{($target/$vw-context - $vw-converted + vw)});
}

@mixin connect-epicons($path: "../../fonts") {
    @font-face {
        font-family: "iconsEP";
        src: url("#{$path}/iconsEP.woff2") format("woff2"), url("#{$path}/iconsEP.woff") format("woff");
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }
}

$rem-baseline: 16px !default;
$rem-fallback: false !default;
$rem-px-only: false !default;

@function rem-separator($list, $separator: false) {
    @if $separator == "comma" or $separator == "space" {
        @return append($list, null, $separator);
    }

    @if function-exists("list-separator") == true {
        @return list-separator($list);
    }

    // list-separator polyfill by Hugo Giraudel (https://sass-compatibility.github.io/#list_separator_function)
    $test-list: ();
    @each $item in $list {
        $test-list: append($test-list, $item, space);
    }

    @return if($test-list == $list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
    font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
    $result: ();
    $separator: rem-separator($values);

    @each $value in $values {
        @if type-of($value) == "number" and unit($value) == "rem" and $to == "px" {
            $result: append($result, $value / 1rem * $rem-baseline + 0px, $separator);
        } @else if type-of($value) == "number" and unit($value) == "px" and $to == "rem" {
            $result: append($result, $value / $rem-baseline + 0rem, $separator);
        } @else if type-of($value) == "list" {
            $value-separator: rem-separator($value);
            $value: rem-convert($to, $value...);
            $value: rem-separator($value, $value-separator);
            $result: append($result, $value, $separator);
        } @else {
            $result: append($result, $value, $separator);
        }
    }

    @return if(length($result) == 1, nth($result, 1), $result);
}

@function rem($values...) {
    @if $rem-px-only {
        @return rem-convert(px, $values...);
    } @else {
        @return rem-convert(rem, $values...);
    }
}

@mixin rem($properties, $values...) {
    @if type-of($properties) == "map" {
        @each $property in map-keys($properties) {
            @include rem($property, map-get($properties, $property));
        }
    } @else {
        @each $property in $properties {
            @if $rem-fallback or $rem-px-only {
                #{$property}: rem-convert(px, $values...);
            }
            @if not $rem-px-only {
                #{$property}: rem-convert(rem, $values...);
            }
        }
    }
}

@mixin input-placeholder {
    &:-ms-input-placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

@mixin retina-display($ratio: 1.5) {
    @media (-webkit-min-device-pixel-ratio: $ratio), (min-resolution: #{$ratio*96}dpi) {
        @content;
    }
}
