$blue:          #2181F8 !default;
$blue-light:    #D3E6FE !default;

$green:			#00BE53 !default;
$green-light:	#CCF2DC !default;

$orange:		#FBB032 !default;
$orange-light:	#FEF0CC !default;

$red:			#FF3644 !default;
$red-light:		#FED6D8 !default;

$white:			#FFFFFF !default;
$black:         #000000 !default;
$black-light:   #2F2F2F !default;	// text
$gray:          #9e9e9e !default;	// text
$gray-light:    #E0E0E0 !default;	// border
$gray-lighter:  #f5f5f5 !default;	// background


$blue-guideline:          #076DEB !default;
$blue-hover-guideline:    #0863D2 !default;
$gray-dark:               #757575 !default;
$red-guideline:           #E6000F !default;
$red-hover:               #D2000E !default;
$orange-guideline:        #EA6813 !default;
$green-guideline:         #25854E !default;
$green-hover:             #18703D !default;
