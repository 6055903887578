.validate-messages{
    position: absolute;
	left: 37px;
    right: 0;
	z-index: 2;
    min-width: 140px;
    width: calc(100% - 37px);
    padding: 9.5px 10px;
    margin-top: -37px;
    color: $black;
    background: $red-light;
	cursor: pointer;
    font-size: 14px;
    line-height: 18px;

    &__item{
        display: block;
    }
}

.validate-border{
    background-image: url('../../img/required-circle.png');
    background-position: calc(100% - 13px) 5px;
    background-repeat: no-repeat;
    border: 1px solid $red !important;
}
