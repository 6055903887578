.container-fluid-modal{
	margin-right: auto;
	margin-left: auto;
	overflow: hidden;
}

.wr-modal-flex{
    max-width: 100%;
    width: 100%;
}

.modal-flex{
	$root: &;
	min-height: 1px;

	&__form{
		position: relative;
		display: flex;
		flex-direction: column;
		height: 100%;
		min-height: 1px;
	}

	&__content{
		flex: 1;
		height: 100%;
		min-height: 1px;
		overflow: auto;
		margin-bottom: 27px;

		> .input-label{
			&:first-child{
				margin-top: 0;
			}
		}

		.form-group{
			margin-bottom: 0;
        }

        > .form-group{
            &:first-child{
				.input-label{
                    margin-top: 0;
                }
			}
		}
	}

	&__btns{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
        flex: 0 0 auto;
        width: 100%;
		min-height: 1px;
		padding-top: 15px;
		border-top: 1px solid $gray-light;

		.btn{
			min-width: 130px;
			margin-left: 10px;
			@include text-nowrap;

			&:first-child{
				margin-left: 0;
			}

			&.dropdown-toggle{
				min-width: auto;
			}
		}

		&--270{
			.btn{
				min-width: 270px;
			}
		}

		&--50pr{
			#{$root}{
				&__btns-left{
					width: calc(50% - 5px);
				}

				&__btns-right{
					justify-content: flex-end;
					width: calc(50% - 5px);
				}
			}
		}
	}

	&__btns-left{
		display: flex;
		align-items: center;
	}

	&__btns-right{
		display: flex;
		align-items: center;
		margin-left: auto;
	}

	@include tablet-landscape-up{
		&__btns{
			&--270{
				.btn{
					min-width: 130px;

					&.dropdown-toggle{
						min-width: auto;
					}
				}
			}
		}
	}

	@include phone-only{
		&__content{
			margin-bottom: 17px;
		}

		&__btns{
			.btn{
				min-width: 110px;

				&.dropdown-toggle{
					width: auto;
					min-width: auto;
				}
			}
		}

		&__btns{
			&--270{
				.btn{
					min-width: 110px;

					&.dropdown-toggle{
						min-width: auto;
					}
				}
			}
		}
	}

	@include phone-extra-small{
		&__btns{
			&--50pr{
				.btn{
					width: 100%;
					max-width: 100%;
					margin-left: 0;
				}
			}
		}
	}
}
