@import "@scss/main";

.loader{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        background-color: transparent;
        max-height: 50%;
    }

    &Bg{
        background: rgba($white, 0.5);
    }
}
