@import "./assets/scss/main";

*{
    box-sizing: border-box;
    -webkit-text-size-adjust: none;
}

@media (hover: hover) {
    *{
        scrollbar-width: thin;
        scrollbar-color: $gray-light $gray-lighter;

        &::-webkit-scrollbar-track{
            margin-left: 5px;
            background-color: $gray-lighter;
        }

        &::-webkit-scrollbar{
            width: 8px;
            margin-left: 5px;
            background-color: $gray-lighter;
        }

        &::-webkit-scrollbar-thumb{
            background-color: $gray-light;
        }
    }
}

#root {
    height: 100%;
}

body,
html{
    height: 100%;
    margin: 0;
    padding: 0;
}

html {
    max-width: 100vw;
}

body{
    font-size: 16px;
    line-height: 24px;
}

textarea,
select,
input[type="number"],
input[type="search"],
input[type="email"],
input[type="text"],
input[type="password"]{
	width: 100%;
	height: 40px;
	padding: 0 15px;
	vertical-align: middle;
	font-size: 16px;
	line-height: 38px;
	color: $black;
	border: 1px solid $gray-dark;
	background-color: $white;
    appearance: none;
	border-radius: 0;

	&:placeholder-shown{
		line-height: normal;
		color: $gray-dark;
	}

	@include input-placeholder {
		color: $gray-dark;
		opacity: 1;
	}

	&:focus{
		border: 1px solid $blue;
        outline: none;
    }

    @include my-media(767) {
		-webkit-appearance: none;
    }
}

textarea{
    min-height: 150px;
    padding-top: 10px;
	resize: none;
    line-height: 22px;

    @include my-media(991) {
        line-height: 22px;
    }
}

select{
	padding-right: 44px;
	appearance: none;
	background: $white url(./assets/img/arrow-select-new.png) right center no-repeat;
	cursor: pointer;

	&:invalid{
		color: $gray !important;
    }

    @include my-media(991) {
        padding-right: 34px;
		background: $white url(./assets/img/arrow-select-new2.png) right center no-repeat;
    }
}

button{
    border: none;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &:focus-visible:not(:hover, :active){
        outline: 1px solid $blue;
    }
}

h1,
h2,
h3,
h4,
h5{
    margin: 0;
    padding: 0;
}

p{
    margin: 0;
    padding: 0;
}

a{
    text-decoration: none;
}

ol,
ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.row{
    display: flex;
    margin: 0 -5px;
}

.col{
    width: 100%;
    padding: 0 5px;

    &--auto{
        width: auto;
    }
}

.bg-green{
    background: $green;
}

.bg-red{
    background: $red;
}

.txt-green{
    color: $green;
}

.txt-orange{
    color: $orange;
}

.hidden{
    visibility: hidden;
    opacity: 0;
}

img{
    image-rendering: -webkit-optimize-contrast;
    @include retina-display(1.5) {
        image-rendering: auto;
    }
}

$input-btn-padding-y:   .438rem !default;
$border-radius:         0;
$border-radius-lg:      0;
$border-radius-sm:      0;

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import 'bootstrap/scss/_buttons.scss';

@import "./assets/scss/blocks/_btn";
@import "./assets/scss/blocks/_input-label";
@import "./assets/scss/blocks/_form-group";
@import "./assets/scss/blocks/_validate";
@import "./assets/scss/blocks/_modal-flex";
@import "./assets/scss/blocks/_ccheck";

// Because some bootstrap or another files rewrite font-family to Arial or another one.
body,
button,
div, span, p,
textarea, select, input,
h1, h2, h3, h4, h5, h6{
    font-family: Roboto, Segoe UI, Arial, sans-serif;
}


.icon-tabler {
    stroke: $black-light;
}

button, a {
    &:hover {
        .icon-tabler:not(.icon-tabler-ban) {
            stroke: $blue-guideline;
        }
    }
}

.icon-tabler-dots {
    fill: $black;
}
