.btn{
    &--50{
        padding-top: .875rem !important;
        padding-bottom: .875rem !important;
        line-height: 1.25 !important;
    }

    &--50{
        @include my-media(991){
            padding: 0.438rem 0.75rem!important;
            line-height: 1.5!important;
        }
    }

    &.disabled,
    &:disabled{
        pointer-events: none;
        cursor: initial;
        opacity: 0.5;
    }

    &:focus,
    &.focus{
        box-shadow: none!important;
    }

    &-primary {
        border-color: $blue;
        background-color: $blue;
        color: $white;

        &:hover, &:focus{
            color: $white;
            background-color: darken($blue, 7.5%);
            border-color: darken($blue, 7.5%);
        }

        &.disabled,
        &:disabled{
            border-color: $blue;
            background-color: $blue;
            color: $white;
        }
    }

    &-light{
        background-color: $gray-lighter;
        border-color: $gray-lighter;

        &:hover, &:focus {
            background-color: $gray-lighter;
            border-color: $gray-lighter;
        }

        &:not(:disabled):not(.disabled){
            &:active, &.active {
                background-color: darken($gray-lighter, 10%);
                border-color: darken($gray-lighter, 12.5%);
            }
        }
    }

    &-success {
        border-color: $green;
        background-color: $green;
        color: $white;

        &:hover, &:focus{
            color: $white;
            background-color: darken($green, 7.5%);
            border-color: darken($green, 7.5%);
        }

        &.disabled,
        &:disabled{
            border-color: $green;
            background-color: $green;
            color: $white;
        }
    }
}

button{
    &.disabled,
    &:disabled{
        pointer-events: none;
        cursor: initial;
        opacity: 0.5;
    }
}
