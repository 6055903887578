.input-label{
	$root: &;
	position: relative;
	display: block;
	margin: 20px 0 9px 0;
	font-size: 18px;
	line-height: 24px;
	color: $black;

	&--required{
		&:after{
			content: "*";
			padding-left: 5px;
			font-size: 18px;
			line-height: 16px;
			color: $red;
		}
	}

    &--first{
        margin-top: 0;
    }

    @include phone-only{
		font-size: 17px;
	}
}
